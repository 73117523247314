@import "core/config";

// body {
//     color: $primary;
// }

@import "core/config";
@import "core/root";
@import "core/typography";
/*-- FONTS FAMILY --*/
@font-face {
  font-family: "roboto_slabregular";
  src: url("../fonts/roboto-regular-webfont.woff2") format("woff2"),
    url("../fonts/roboto-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
li {
  list-style: none;
}
body {
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  color: $textColor;
  background: $theme_secondary;
}
.dropdown-menu {
  background: $theme_primary;
  .dropdown-item {
    color: $textColor !important;
    &:focus {
      outline: 0;
      box-shadow: none;
    }
    &:hover {
      background: $linkhover;
      color: $light;
    }
  }
}

.btn {
  position: $p-relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  &.outline-btn {
    &.btn-secondary {
      background: transparent;
    }
  }
  &:focus {
    box-shadow: none !important;
    outline: 0px !important;
  }
  &:after {
    content: "";
    display: block;
    position: $p-absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, #fff 30%, transparent 12.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
  }
  &:active {
    &:after {
      transform: scale(0, 0);
      opacity: 0.3;
      transition: 0s;
    }
  }
}
.customButtonsmall {
  max-height: 45px !important;
  padding: 0.6rem 1.5rem !important;
  min-width: 150px;

  &.btn-width-sm {
    @include respond(phone) {
      min-width: 133px;
    }
  }
}
.customButtonLage {
  max-height: 45px !important;
  padding: 0.6rem 1.5rem !important;
  min-width: 200px;
  @include respond(phone) {
    width: 100%;
  }
  &.btn-width-sm {
    @include respond(phone) {
      min-width: 150px;
      max-width: 150px;
    }
  }
}
.customButtonIcon {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.bgSecondary {
  background: $theme_secondary;
}
.bgPrimary {
  background: $theme_primary;
}
.textSecondary {
  color: $theme_secondary;
}
.textPrimary {
  color: $theme_primary;
}
.txtLight {
  color: $light;
}
.bgLight {
  background: $light;
}
.rotate-0 {
  transform: rotate(0deg);
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background: $theme_primary !important;
}

::-webkit-scrollbar {
  width: 1px !important;
  height: 2px !important;
}
::-webkit-scrollbar-thumb {
  background: $theme_primary !important;
}
::placeholder {
  color: #29388d;
}
.modal-fullscreen .modal-dialog {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  max-width: 763px;
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: none;
  border-radius: 0;
}

.modal-fullscreen .modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}