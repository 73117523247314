// TRANSITION MIXIN
@mixin transition($transition){
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -ms-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}
// TRANSFORM MIXIN
@mixin transform($transform){
    -webkit-transform: $transform;
    -moz-transform: $transform;
    -ms-transform: $transform;
    -o-transform: $transform;
    transform: $transform;
}
// BOX SHADOW MIXIN
@mixin boxShadow ($boxShadow){
    -webkit-box-shadow: $boxShadow;
    -moz-box-shadow: $boxShadow;
    box-shadow: $boxShadow;
}
// MEDIA QUERIES WITH BREAKPOINTS 
@mixin respond($breakpoint){
    @if $breakpoint == phone {
        @media (max-width : 767px) { @content; }
    }
    @if $breakpoint == tablet-sm-desktop {
        @media (min-width: 768px) and (max-width : 991px) { @content; }
    }
    @if $breakpoint == phone-tab-sm-desktop {
        @media (min-width: 0px) and (max-width : 991px) { @content; }
    }
    @if $breakpoint == sm-desktop {
        @media (min-width: 992px) and (max-width : 1199px) { @content; }
    }
    @if $breakpoint == lg-desktop {
        @media (min-width : 1200px) { @content; }
    }
}