// Theme Colors
$primary: #007bff;
$secondary: #6c757d;
$success: #28a745;
$info: #17a2b8;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #343a40b5;

$authCardBg: #e7e5e3;

$linkhover: #29388d;
$textColor: #b9bcc3;

$theme_primary: #29388d;
$theme_secondary: #29388d;
$darkShadow: #0000001a;
$asideBorderBottom: #cccccc17;

// Breakpoints
$breakpointXS: 0px;
$breakpointSM: 576px;
$breakpointMD: 768px;
$breakpointLG: 992px;
$breakpointXL: 1200px;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "info": $info,
    "warning": $warning,
    "danger": $danger,
    "light": $light,
    "dark": $dark,
  ),
  $theme-colors
);

$breakpoints: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$breakpoints: map-merge(
  (
    "breakpoint-xs": $breakpointXS,
    "breakpoint-sm": $breakpointSM,
    "breakpoint-md": $breakpointMD,
    "breakpoint-lg": $breakpointLG,
    "breakpoint-xl": $breakpointXL,
  ),
  $breakpoints
);
$p-relative: relative;
$p-absolute: absolute;
$p-fixed: fixed;
$p-static: static;
$transparent: transparent;

$t-capitalize: capitalize;
$t-lowercase: lowercase;
$t-uppercase: uppercase;

$d-block: block;
$d-none: none;
$d-flex: flex;
