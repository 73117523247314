input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
  background: none!important;
}
 

.count-border {
  border: 1px solid #ccc;
}

.shadowStyle {
  box-shadow: 5px 5px 0px 0px #2e364870, 10px 10px 0px 0px #2e364847;
}

.CardShabow {
  box-shadow: 4px -4px 6px -2px #292929;
}

.overlayBg {
  background: linear-gradient(to top, rgba(216, 216, 216, 0.64), rgba(255, 255, 255, 0.93)) !important;
}

.cardSmMinHeight {
  min-height: 158px;
}

.roundedImg {
  border-radius: 50%;
}

.teacherCard {
  background: linear-gradient(to top, rgba(216, 216, 216, 0.64), rgba(255, 255, 255, 0.93)) !important;
  border-radius: 10px;
}

.backgroundCard {
  background-image: url("./assets/images/FormBG.svg");
  background-position: inherit;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
}

.w-40 {
  width: 40%;
}

.w-60 {
  width: 60%;
}

.TeacherDetail label {
  font-size: 12px;
  margin: 0px;
}
/* upload */

.striped_border {
  width: 150px;
  height: 150px;
  border: 1px dashed #a6a6a6;

  border-radius: 50% !important;
}
.img-upload-thumb-row .form-group i,
.img-upload-thumb-row .form-group .uplopadText {
  left: 50% !important;
  top: 50% !important;
  position: absolute;

  line-height: 0px;
  -webkit-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}
.zIndex-1 {
  z-index: 1;
}
.zIndex-0 {
  z-index: 0;
}
.hvrbox {
  position: absolute !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -52%);
  height: 150px;
  width: 150px;
  border: rebeccapurple;
  border-radius: 50%;
  overflow: hidden;
}
.hvrbox .hvrbox-layer_top {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;

  -webkit-transition: all 0.4s ease-in-out 0s;
  -o-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}

.hvrbox .hvrbox-text {
  font-size: 18px;
  display: inline-block;
  top: 50%;
  left: 50%;
}
.transform-center {
  left: 50% !important;
  top: 50% !important;
  -webkit-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}

.hvrbox:hover .hvrbox-layer_top,
.hvrbox.active .hvrbox-layer_top {
  opacity: 1;
  z-index: 3;
}

.MuiInputBase-input:focus {
  outline: 0;
}

.shadowInput {
  box-shadow: 7px 21px 11px -10px #00000014;
}

.MuiInput-underline:after {
  border-bottom: 1px solid #000 !important;
}
.MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 1px solid #000 !important;
}

.ColorPickerWrapper input:focus ~ label {
  top: -17px !important;
  font-weight: 300 !important;
}
.imagePreview {
  min-height: 160px;
  min-width: 200px;
  /* background: red; */
}
.imagePreview .striped_border {
  min-width: 270px;
  height: 150px;
  border: 0px;
  border-radius: 0% !important;
}
.imagePreview .hvrbox {
  width: 270px;
  height: 150px;
  border-radius: 0px;
}
.imagePreview .hvrbox img {
  width: 100% !important;
}
.imagePreview .PlusIcon {
  display: none !important;
}

.imagePreview .striped_border {
  /* background: $theme_secondary; */
  background: #29388d !important;
}
/* .smallTableList .MuiTableRow-root{
  height: 45px!important;
} */
.h-150 {
  height: 150px;
}
.ck-editor__main p {
  min-height: 230px;
}

/* profile */

.profile-card .profileImg {
  width: 130px;
  height: 130px;
}

.profile-card .card-icon {
  z-index: 2;
  background: #d5d5d5;
  margin-right: 0px;
  border: 2px solid #ccc;
  border-radius: 62%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-card .profileImg .card-icon-img {
  width: 100%;
}

.text-content {
  min-height: 116px;
}

.fs-24 {
  font-size: 24px;
}

.fs-18 {
  font-size: 18px;
}

.socialIcon ul {
  display: flex;
  justify-content: space-around;
  margin: 0px;
  padding: 0px;
}

.socialIcon ul li a {
  width: 50px;
  height: 50px;
  color: #000;
  border: 1px solid #000;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profileHeightLage {
  min-height: 257px;
  display: flex;
  justify-content: center;
  align-items: center;
}

 #hide input[type="file"] {
  opacity: 0;
} 
#hide input[type="file"] + label {
  padding: 10px 32px 10px 0px;
  
  border-bottom: solid 1px #666f77;
  border-radius: 0px;
  top: 0px !important;
  left: 15px;
  width: 90%;
}
/* #hide input[type="file"]:active + label {
  background-image: none;
  background-color: #2d6c7a;
  color: #ffffff;
}  */
.radius-30{
  border-radius: 30px;
}

@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-dropdowns/styles/material.css";
