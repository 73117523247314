@import "../../core/config";
.fullHeight {
  height: calc(100vh - 220px);
  overflow-y: auto;

  @include respond(tablet-sm-desktop) {
    height: auto;
  }
  @include respond(phone) {
    height: auto;
  }
}
.appTheme {
  .customInputBox {
    margin: 0px !important;
  }
  .ColorPickerWrapper {
    min-height: 50px;

    .MuiTextField-root {
      top: 6px;
      width: 100%;
      padding: 0px;
      .MTableToolbar-root-5 {
        padding: 0px;
      }
    }
    label {
      font-size: 16px;
      font-weight: normal;
      position: absolute;
      pointer-events: none;
      left: 0px;
      font-weight: 500;
      color: $dark;
      top: 7px;
      margin-bottom: 0px;
      transition: 200ms ease all;
    }
  }
  input {
    box-shadow: 7px 21px 11px -10px #00000014;
  }
}
// .MuiInputBase-input {
//   &:focus ~ label {
//     top: -17px!important;
//     font-weight: 300 !important;
//   }
// }

.customInputBox {
  .tooltip-ex {
    display: none;
    @include respond(phone) {
      display: none;
    }
  }
  .form-control,
  .custom-file-label,
  .form-control-file {
    width: 100%;
    padding: 0px 5px;
    background: transparent !important;
    border-radius: 0px !important;
    border: 0px !important;
    border-bottom: 1px solid $dark !important;
    &:focus {
      color: #29388d;
      outline: 0;
      box-shadow: none;
      background: transparent!important;
      box-shadow: none;
      border-color: $danger !important;
      -webkit-transition: 0.5s ease-in-out !important;
      -o-transition: 0.5s ease-in-out !important;
      transition: 0.5s ease-in-out !important;
    }
    .css-g1d714-ValueContainer {
      padding: 0px;
    }
  }

  label {
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 15px;
    font-weight: 500;
    color: $dark;
    top: 7px;
    margin-bottom: 0px;
    transition: 300ms ease all;
  }
  .form-control {
    &:focus ~ label {
      top: -15px;
      font-size: 13px;
      left: 15px;
      font-weight: 300;
    }
  }
}
.valueActive {
  label {
    top: -15px !important;
    font-weight: 300 !important;
    left: 15px !important;
    font-size: 13px;
  }
}
input:-internal-autofill-selected {
  background: transparent;
  ~ label {
    top: -15px !important;
    font-weight: 300 !important;
    left: 15px !important;
    font-size: 13px;
  }
}
